// Imports
import React from "react";

const TravelDetails = () => (
    <section id="details" className="travel-details row">
        <div className="content-wrapper clearfix">
            <div className="one-third column">
                <div className="heading">
                    {/* <h2><span role="img" aria-label="airplane">✈️</span> Airports</h2> */}
                    <h2>Airports</h2>
                    <p>We recommend flying in to one of the major airports and renting a car to finish the journey.</p>
                </div>
                <div className="content">
                    <div className="entry">
                        <strong>Oakland International Airport (OAK)</strong> <br/>
                        Approximately 60-minute drive.
                    </div>

                    <div className="entry">
                        <strong>San Francisco International Airport (SFO)</strong> <br/>
                        Approximately 75-minute drive.
                    </div>

                    <div className="entry">
                        <strong>San Jose International Airport (SJC)</strong> <br/>
                        Approximately 90-minute drive.
                    </div>
                </div>
            </div>

            <div className="two-thirds column hotels">
                <div className="heading">
                    {/* <h2><span role="img" aria-label="home">🏡</span> Lodging</h2> */}
                    <h2>Lodging</h2>
                    <p>The venue is located between downtown Napa (15 minutes) and downtown Sonoma (15 minutes), so both cities are good choices for a hotel or Airbnb for the weekend.</p>
                </div>
                <div className="content row">
                    <div className="one-half column">
                        <div className="entry">
                            <a href="https://www.carnerosresort.com/" target="_blank" rel="noopener noreferrer">Carneros Resort &amp; Spa</a> <br/>
                            4048 Sonoma Hwy, Napa, CA 94559
                        </div>

                        <div className="entry">
                            <a href="https://archerhotel.com/" target="_blank" rel="noopener noreferrer">Archer Hotel Napa</a> <br/>
                            1230 1st St, Napa, CA 94559
                        </div>
                        
                        <div className="entry">
                        <a href="https://www.hilton.com/en/hotels/apcighx-hampton-suites-napa/" target="_blank" rel="noopener noreferrer">Hampton Inn &amp; Suites</a> <br/>
                            945 Hartle Court, Napa, CA 94559
                        </div>

                        <div className="entry">
                            <a href="https://embassysuites3.hilton.com/en/hotels/california/embassy-suites-by-hilton-napa-valley-NAPVLES/index.html" target="_blank" rel="noopener noreferrer">Embassy Suites</a> <br/>
                            1075 California Blvd., Napa, CA 94559
                        </div>
                    </div>
                    <div className="one-half column">
                        <div className="entry">
                            <a href="https://www.riverterraceinn.com/" target="_blank" rel="noopener noreferrer">River Terrace Inn</a> <br/>
                            1600 Soscol Ave, Napa, CA 94559
                        </div>

                        <div className="entry">
                            <a href="https://www.hotelindigonapa.com/" target="_blank" rel="noopener noreferrer">Hotel Indigo</a> <br/>
                            4195 Solano Ave, Napa, CA 94558
                        </div>

                        <div className="entry">
                            <a href="https://www.eldoradosonoma.com/" target="_blank" rel="noopener noreferrer">El Dorado Hotel</a> <br/>
                            405 1st St W, Sonoma, CA 95476
                        </div>

                        <div className="entry">
                            <a href="http://www.napahiltongardeninn.com/" target="_blank" rel="noopener noreferrer">Hilton Garden Inn</a> <br/>
                            3585 Solano Ave, Napa, CA 94558
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="one-third column">
                <div className="heading">
                    <h2>Activities</h2>
                </div>
                <div className="content">
                    <div className="entry">
                        <strong>Wine tasting</strong>
                        <div className="row">
                            <div className="one-half column">
                                – Frog's Leap <br/>
                                – A winery <br/>
                                – Another winery <br/>
                                – Another winery
                            </div>
                            <div className="one-half column">
                                – Another winery <br/>
                                – Another winery <br/>
                                – Another winery <br/>
                                – Another winery
                            </div>
                        </div>
                    </div>

                    <div class="entry">
                        <strong>Hiking &amp; Kayaking</strong>
                        <div className="row">
                            <div className="one-half column">
                                – Location one <br/>
                                – Location two
                            </div>
                            <div className="one-half column">
                                – Location one <br/>
                                – Location two
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </section>
);

export default TravelDetails;
