// Imports
import React from "react";

const WeddingDetails = () => (
    <section id="wedding" className="wedding-details row">
        <div className="content-wrapper clearfix">
            <div className="one-half column">
                <div className="heading">
                    {/* <h2><span role="img" aria-label="wedding">💒</span> Ceremony &amp; Reception</h2> */}
                    <h2>Ceremony &amp; Reception</h2>
                    <div className="detail">
                        <strong>When:</strong> 5:15 PM on Friday, June 11, 2021 <br />
                        <strong>Where:</strong> 4048 Sonoma Hwy, Napa, CA 94559 <br />
                        <strong>Attire:</strong> Formal
                    </div>
                </div>
                <div className="content">
                    <p>
                        Both the ceremony and reception will be held 
                        at <a href="https://www.carnerosresort.com/" target="_blank" rel="noopener noreferrer">Carneros Resort &amp; Spa</a> in
                        Napa, California. Dinner will be held outside at the beautiful Hilltop location. After dinner, we’ll continue with
                        drinks and dancing indoors until 12 am. Napa tends to be cooler in the evenings – we recommend bringing an extra layer.
                    </p>

                    <p>Although we love your little ones, this will be an adults-only celebration, aside from the children who are part of our ceremony.</p>
                </div>
            </div>

            <div className="one-half column">
                <div className="heading">
                    {/* <h2><span role="img" aria-label="party">🥳</span> Welcome party</h2> */}
                    <h2>Welcome party</h2>
                    {/* <div className="detail">
                        <strong>When:</strong> 7:30 PM on Thursday, June 10, 2021 <br />
                        <strong>Where:</strong> 1026 1st St, Napa, CA 94559 <br />
                        <strong>Attire:</strong> Smart casual
                    </div> */}
                </div>
                <div className="content">
                    <p>
                        Unfortunately, at this time, we do not have approval to host a welcome party as originally planned. As the restaurant's
                        situation may change before June, we will keep you updated!
                    </p>
                    {/* <p>
                        We will be hosting a welcome party in downtown Napa
                        at <a href="https://www.ristoranteallegria.com/" target="_blank" rel="noopener noreferrer">Ristoranté Allegria</a> to 
                        kick off the wedding weekend on Thursday evening. 
                        It will include small bites and drinks.
                    </p>
                    <p>
                        Weather permitting, it will be held outside on the patio – we recommend bringing an extra layer. 
                        Your little ones are welcome!
                    </p> */}
                </div>
            </div>
        </div>
    </section>
);

export default WeddingDetails;
