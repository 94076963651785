// Imports
import React from "react";
import { Helmet } from "react-helmet";

// Components
import Intro from "../components/intro";
import WeddingDetails from "../components/weddingDetails";
// import OurStory from "../components/ourstory";
import TravelDetails from "../components/travelDetails";
import RegistryDetails from "../components/registryDetails";

// Styles
import '../styles/styles.scss';

// Javascripts
if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
        easing: 'easeInOutCubic',
        speed: 1100,
        speedAsDuration: true
    });
}

export default () => (
<>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Emily and Matt's Wedding</title>
    </Helmet>

    <Intro></Intro>
    <WeddingDetails></WeddingDetails>
    {/* <OurStory></OurStory> */}
    <TravelDetails></TravelDetails>
    <RegistryDetails></RegistryDetails>
</>
)