// Imports
import React from "react";

const RegistryDetails = () => (
    <section id="registry" className="registry-details">
        <div className="content-wrapper clearfix">
            <div className="heading">
                {/* <h2><span role="img" aria-label="present">🎁</span> Registry</h2> */}
                <h2>Registry</h2>
            </div>

            <div className="row">
                <div className="two-thirds column">    
                    <div className="content">
                        <p>
                            We are incredibly thankful for the effort you’ll be making to join us for our special
                            day in Napa. Your presence at our wedding is our present! However, for friends and family
                            who have asked for gift ideas, we’ve created an online registry and honeymoon fund for
                            our trip to Africa.
                        </p>
                    </div>
                </div>

                <div className="one-third column">
                    <div className="content row">
                        <div className="one-half column entry list">
                            <strong>Registry</strong> <br/>
                            – <a href="https://www.amazon.com/wedding/share/emilyandmattwedding" target="_blank" rel="noopener noreferrer">Amazon</a> <br />
                            – <a href="https://www.crateandbarrel.com/gift-registry/emily-kellogg-and-matthew-bango/r6031762" target="_blank" rel="noopener noreferrer">Crate &amp; Barrel</a>
                        </div>

                        <div className="one-half column entry list">
                            <strong>Honeymoon fund</strong> <br />
                            – <a href="https://www.zola.com/registry/emilyandmatthewmay1" target="_blank" rel="noopener noreferrer">Zola</a> <br />
                            – <a href="https://paypal.me/pools/c/8k2ZggqmeM" target="_blank" rel="noopener noreferrer">PayPal</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default RegistryDetails;
