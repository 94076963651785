// Imports
import React from "react";
import { Link } from "gatsby";

const Intro = () => (
    <section className="intro">
        <header className="clearfix">
            <div className="branding">E / M</div>
            <nav>
                <ul>
                    <li><Link to="/#wedding">Wedding</Link></li>
                    {/* <li id="our-story"><Link to="/#ourstory">Our Story</Link></li> */}
                    <li><Link to="/#details">Details</Link></li>
                    <li><Link to="/#registry">Registry</Link></li>
                </ul>
            </nav>
        </header>
        <div className="content-wrapper">
            <div className="text-wrapper">
                <div className="intro-text">
                    <h1 className="emily">Emily</h1>
                    <span className="conjunction">and</span>
                    <h1 className="matt">Matt</h1>
                </div>
                <div className="intro-detail">
                    Join the celebration as Emily Kellogg and <br /> Matt Bango say "I do” in Napa, California.
                </div>
                <div className="intro-subdetail">
                    We're so excited to have you join us on our special day!
                </div>
            </div>
        </div>
        <div className="middle-row">
            <span className="detail-text scroll">← Scroll</span>
            <span className="detail-text date">06 / 11</span>
        </div>
        <div className="bottom-row">
            <span className="detail-text below">Details Below</span>
            <span className="detail-text location">Napa, California</span>
        </div>
    </section>
);

export default Intro;
